import {useCallback} from 'react';

import {notifyError} from '@shared-web/lib/notification';
import {Custom} from '@shared-web/lib/react';

import {BrightnessControl} from '@src/components/brightness_control';
import {setLightBrightness} from '@src/lib/commands';
import {LightType} from '@src/lib/parse_light';
import {useLight} from '@src/stores/entities_store';

interface LightBrightnessProps {
  entityId: string;
}

export const LightBrightness: Custom<LightBrightnessProps, 'div'> = props => {
  const {entityId, ...rest} = props;
  const light = useLight(entityId);

  const handleBrightnessChange = useCallback(
    async (newBrightness: number) => {
      if (!light) {
        return;
      }
      await setLightBrightness(light, newBrightness).catch(notifyError);
    },
    [light]
  );

  if (light === undefined || light.type !== LightType.Brightness) {
    return <></>;
  }

  return (
    <BrightnessControl
      brightness={light.brightness}
      onBrightnessChange={handleBrightnessChange}
      {...rest}
    />
  );
};

LightBrightness.displayName = 'LightBrightness';

import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {UnthemedButton} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {lightBulbMultiIcon} from '@shared-web/components/icons/light_bulb_multi';

import {MultiLightBrightness} from '@src/components/multi_light_brightness';
// import {LightBrightnessSlider} from '@src/components/light_brightness_slider';
import {setLightState} from '@src/lib/commands';
import {LightState} from '@src/lib/parse_light';
import {useLights} from '@src/stores/entities_store';

interface MultiLightButtonProps {
  entityIds: string[];
  name: string;
}

export const MultiLightButton: FC<MultiLightButtonProps> = props => {
  const {entityIds, name} = props;
  const lights = useLights(entityIds);

  const anyOn = lights.find(light => light.state === LightState.On) !== undefined;
  const handleClick = useCallback(async () => {
    await Promise.all(
      lights.map(async light => setLightState(light, anyOn ? LightState.Off : LightState.On))
    );
  }, [anyOn, lights]);

  const color = anyOn ? '#c4940c' : '#7ca6c7';

  return (
    <Wrapper>
      <Button onClickAsync={handleClick}>
        <Icon icon={lightBulbMultiIcon} color={color} width="100%" height="40%" />
        <Text viewBox="0 0 100 100">
          <text x="50" y="70" fontSize="14" fontWeight={600} textAnchor="middle" fill="#ccc">
            {name}
          </text>
        </Text>
      </Button>
      <StyledMultiLightBrightness entityIds={entityIds} />
    </Wrapper>
  );
};

MultiLightButton.displayName = 'MultiLightButton';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff22;
`;

const Button = styled(UnthemedButton)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  &:hover {
    background-color: #ffffff22;
  }
`;

const Icon = styled(SvgIcon)`
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 0;
`;

const Text = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledMultiLightBrightness = styled(MultiLightBrightness)`
  position: absolute;
  height: 20%;
  right: 0;
  bottom: 0;
  left: 0;
`;

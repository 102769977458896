import {FC, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {UnthemedButton} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {powerIcon} from '@shared-web/components/icons/power';

import {getOverlayTimer} from '@src/lib/overlay_timer';
import {setAppHidden} from '@src/stores/app_state_store';

interface ScreenButtonProps {}

export const ScreenButton: FC<ScreenButtonProps> = () => {
  const handleClick = useCallback(() => setAppHidden(true), []);
  const [overlayTimer, setOverlayTimer] = useState(getOverlayTimer());

  useEffect(() => {
    const interval = setInterval(() => {
      setOverlayTimer(getOverlayTimer());
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <Button onClick={handleClick}>
        <Icon icon={powerIcon} color="#ffffff44" width="100%" height="40%" />
        <Text viewBox="0 0 100 100">
          <text x="50" y="70" fontSize="14" fontWeight={600} textAnchor="middle" fill="#ccc">
            Écran
          </text>
        </Text>
        <Progress>
          <ProgressBar
            style={{right: `${100 * (1 - overlayTimer.remaining / overlayTimer.total)}%`}}
          />
        </Progress>
      </Button>
    </Wrapper>
  );
};

ScreenButton.displayName = 'ScreenButton';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff22;
`;

const Button = styled(UnthemedButton)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  &:hover {
    background-color: #ffffff22;
  }
`;

const Icon = styled(SvgIcon)`
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 0;
`;

const Text = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Progress = styled.div`
  position: absolute;
  height: 10%;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff22;
  transition: right linear 100ms;
`;

import {getAppStateStore, setAppHidden} from '@src/stores/app_state_store';

export function markActivity(): void {
  lastAction = Date.now();
}

const MIN_INACTIVITY_FOR_OVERLAY_MS = 10 * 1000;
let lastAction = Date.now();

export function getOverlayTimer(): {remaining: number; total: number} {
  return {
    remaining: MIN_INACTIVITY_FOR_OVERLAY_MS - (Date.now() - lastAction),
    total: MIN_INACTIVITY_FOR_OVERLAY_MS,
  };
}

export function startOverlayTimer(): void {
  lastAction = Date.now();
  setInterval(() => {
    if (Date.now() - lastAction > MIN_INACTIVITY_FOR_OVERLAY_MS && !getAppStateStore().hidden) {
      setAppHidden(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  }, 500);
}

import {
  AuthData,
  Connection,
  createConnection,
  getAuth,
  getServices,
} from 'home-assistant-js-websocket';

let connection: Connection | undefined;
const awaiters: ((conn: Connection) => void)[] = [];

const AUTH_STORAGE_KEY = 'AuthToken';

export async function getConnection(): Promise<Connection> {
  if (connection) {
    return connection;
  }
  return new Promise<Connection>(resolve => {
    awaiters.push(resolve);
  });
}

export async function initializeConnection(isRetrying?: boolean): Promise<void> {
  try {
    const auth = await getAuth({
      hassUrl: 'https://192.168.1.167:8123',
      saveTokens: (data: AuthData | null): void => {
        if (data === null) {
          localStorage.removeItem(AUTH_STORAGE_KEY);
        } else {
          localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(data));
        }
      },
      loadTokens: async (): Promise<AuthData | null | undefined> => {
        const authDataStr = localStorage.getItem(AUTH_STORAGE_KEY);
        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.resolve(authDataStr === null ? undefined : JSON.parse(authDataStr));
      },
    });

    connection = await createConnection({auth});
    if (document.location.href.includes('auth_callback')) {
      document.location.href = '/';
    }
    for (const awaiter of awaiters) {
      awaiter(connection);
    }
    console.log('123', await getServices(connection));
  } catch {
    if (!isRetrying) {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      await initializeConnection(true);
    }
  }
}

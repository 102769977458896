import {HassEntities, HassEntity, subscribeEntities} from 'home-assistant-js-websocket';
import {useMemo} from 'react';

import {removeUndefined} from '@shared/lib/type_utils';

import {createDataStore} from '@shared-web/lib/data_store';

import {getConnection} from '@src/lib/connection';
import {Light, parseLight} from '@src/lib/parse_light';

const entitiesDataStore = createDataStore<HassEntities>({});

export const useEntities = entitiesDataStore.useData;
export const setEntities = entitiesDataStore.setData;

export function useEntity(id: string): HassEntity | undefined {
  const entities = useEntities();
  const entity = useMemo(() => entities[id], [entities, id]);
  return entity;
}

export function useLight(id: string): Light | undefined {
  const entity = useEntity(id);
  const light = useMemo(() => (entity ? parseLight(entity) : undefined), [entity]);
  return light;
}

export function useLights(ids: string[]): Light[] {
  const entities = useEntities();
  const lights = useMemo(
    () =>
      ids.map(id => {
        const entity = entities[id];
        return entity ? parseLight(entity) : undefined;
      }),
    [entities, ids]
  );
  return removeUndefined(lights);
}

export async function initializeEntitiesStore(): Promise<void> {
  const connection = await getConnection();
  subscribeEntities(connection, ent => setEntities(ent));
}

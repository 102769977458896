import {useMemo} from 'react';
import {styled} from 'styled-components';

import {removeUndefined} from '@shared/lib/type_utils';

import {useBooleanQueryString} from '@shared-web/lib/use_query_string';

import {LightButton} from '@src/components/light_button';
import {MultiLightButton} from '@src/components/multi_light_button';
import {ScreenButton} from '@src/components/screen_button';
import {markActivity} from '@src/lib/overlay_timer';
import {parseLight} from '@src/lib/parse_light';
import {useEntities} from '@src/stores/entities_store';

enum LightId {
  Muscu = 'switch.lumiere_muscu',
  Bathroom = 'switch.lumiere_salle_de_bain',
  Laundry = 'switch.lumiere_buanderie',
  Desk = 'light.lumiere_bureau',
  LivingRoomSmall = 'light.lumiere_salon_petite',
  Entrance = 'light.lumiere_entree',
  KitchenSpot = 'light.lumiere_cuisine_spot',
  LivingRoom = 'light.lumiere_salon_plafond',
  Kitchen = 'light.lumiere_cuisine',
  Bedroom = 'light.lumiere_chambre',
}

const ALL_IDS = Object.values(LightId);

const LIVING_ROOM_IDS = [
  LightId.LivingRoomSmall,
  LightId.KitchenSpot,
  LightId.LivingRoom,
  LightId.Kitchen,
  // LightId.Entrance,
];

const COORDINATES: Record<LightId, {x: number; y: number}> = {
  [LightId.Entrance]: {x: 43, y: 2},
  [LightId.LivingRoomSmall]: {x: 66, y: 2},
  [LightId.LivingRoom]: {x: 83, y: 2},
  [LightId.Kitchen]: {x: 83, y: 40},
  [LightId.KitchenSpot]: {x: 66, y: 40},

  [LightId.Desk]: {x: 2, y: 2},
  [LightId.Muscu]: {x: 19, y: 2},
  [LightId.Laundry]: {x: 19, y: 21},
  [LightId.Bathroom]: {x: 43, y: 40},
  [LightId.Bedroom]: {x: 5, y: 40},
};

export const HomePage: React.FC = () => {
  const [sleep] = useBooleanQueryString('sleep', true);
  const entities = useEntities();

  useMemo(() => {
    return removeUndefined(Object.values(entities).map(parseLight));
  }, [entities]);

  return (
    // eslint-disable-next-line react/jsx-handler-names
    <Wrapper onClick={markActivity}>
      {Object.entries(COORDINATES).map(([id, {x, y}]) => (
        <Entity key={id} $x={x} $y={y}>
          <LightButton entityId={id} />
        </Entity>
      ))}
      <Entity $x={43} $y={21} key="all">
        <MultiLightButton entityIds={ALL_IDS} name="Toutes" />
      </Entity>
      <Entity $x={74.5} $y={21} key="salon">
        <MultiLightButton entityIds={LIVING_ROOM_IDS} name="Toutes Salon" />
      </Entity>
      {sleep ? (
        <Entity $x={2} $y={21} key="screen">
          <ScreenButton />
        </Entity>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #0000ff22;
  &,
  * {
    cursor: none;
  }
`;

const Entity = styled.div<{$x: number; $y: number}>`
  position: absolute;
  left: ${p => p.$x}vw;
  top: ${p => p.$y}vw;
  display: flex;
  white-space: nowrap;
  width: 15vw;
  height: 15vw;
  border-radius: 5%;
  overflow: hidden;
`;

import {useCallback} from 'react';

import {notifyError} from '@shared-web/lib/notification';
import {Custom} from '@shared-web/lib/react';

import {BrightnessControl} from '@src/components/brightness_control';
import {setLightBrightness, setLightState} from '@src/lib/commands';
import {LightState, LightType} from '@src/lib/parse_light';
import {useLights} from '@src/stores/entities_store';

interface MultiLightBrightnessProps {
  entityIds: string[];
}

export const MultiLightBrightness: Custom<MultiLightBrightnessProps, 'div'> = props => {
  const {entityIds, ...rest} = props;
  const lights = useLights(entityIds);

  const handleBrightnessChange = useCallback(
    async (newBrightness: number) => {
      await Promise.all(
        lights.map(async light =>
          light.type === LightType.Brightness
            ? setLightBrightness(light, newBrightness)
            : setLightState(light, newBrightness > 0 ? LightState.On : LightState.Off)
        )
      ).catch(notifyError);
    },
    [lights]
  );

  return <BrightnessControl onBrightnessChange={handleBrightnessChange} {...rest} />;
};

MultiLightBrightness.displayName = 'MultiLightBrightness';

import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {setAppHidden} from '@src/stores/app_state_store';

interface OverlayProps {}

export const Overlay: FC<OverlayProps> = () => {
  const handleClick = useCallback(() => setAppHidden(false), []);
  return (
    <Wrapper onClick={handleClick}>
      <Dot />
    </Wrapper>
  );
};

Overlay.displayName = 'Overlay';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
`;

const Dot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #666;
  width: 4px;
  height: 4px;
  border-radius: 2px;
`;

import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {UnthemedButton} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {lightBulbIcon} from '@shared-web/components/icons/light_bulb';

import {LightBrightness} from '@src/components/light_brightness';
import {toggleLight} from '@src/lib/commands';
import {LightState, LightType} from '@src/lib/parse_light';
import {useLight} from '@src/stores/entities_store';

interface LightButtonProps {
  entityId: string;
}

export const LightButton: FC<LightButtonProps> = props => {
  const {entityId} = props;
  const light = useLight(entityId);

  const handleClick = useCallback(async () => {
    if (!light) {
      return;
    }
    await toggleLight(light);
  }, [light]);

  if (!light) {
    return <></>;
  }

  const color = light.state === LightState.On ? '#c4940c' : '#7ca6c7';

  return (
    <Wrapper>
      <Button onClickAsync={handleClick}>
        <Icon icon={lightBulbIcon} color={color} width="100%" height="40%" />
        <Text viewBox="0 0 100 100">
          <text x="50" y="70" fontSize="14" fontWeight={600} textAnchor="middle" fill="#ccc">
            {light.name}
          </text>
        </Text>
      </Button>
      {light.type === LightType.Brightness ? <StyledLightBrightness entityId={entityId} /> : <></>}
    </Wrapper>
  );
};

LightButton.displayName = 'LightButton';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff22;
`;

const Button = styled(UnthemedButton)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  &:hover {
    background-color: #ffffff22;
  }
`;

const Icon = styled(SvgIcon)`
  position: absolute;
  top: 10%;
  right: 0;
  bottom: 0;
`;

const Text = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledLightBrightness = styled(LightBrightness)`
  position: absolute;
  height: 20%;
  right: 0;
  bottom: 0;
  left: 0;
`;

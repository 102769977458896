import {SvgIconData} from '@shared-web/components/core/svg_icon';

export const powerIcon: SvgIconData = {
  viewBox: '4.58 0 502 511.51',
  element: (
    <g>
      <path d="M137 123a35 35 0 0 0-46-52 251 251 0 1 0 328-1 35 35 0 0 0-45 52 182 182 0 0 1 64 139 182 182 0 0 1-364 0 182 182 0 0 1 63-138Z"></path>
      <path d="M291 277V35a35 35 0 0 0-70 0v242a35 35 0 1 0 70 0Z"></path>
    </g>
  ),
};

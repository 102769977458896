import {HassEntity} from 'home-assistant-js-websocket';

import {asNumber, asStringArray, asStringEnum} from '@shared/lib/type_utils';

export enum LightType {
  OnOff = 'onoff',
  Brightness = 'brightness',
}

export enum LightState {
  On = 'on',
  Off = 'off',
}

export interface Light {
  id: string;
  name: string;
  type: LightType;
  state: LightState;
  domain: 'light' | 'switch';
  brightness?: number;
}

export function parseLight(entity: HassEntity): Light | undefined {
  const domain = entity.entity_id.startsWith('switch.')
    ? 'switch'
    : entity.entity_id.startsWith('light.')
      ? 'light'
      : undefined;
  if (domain === undefined) {
    return undefined;
  }
  const colorModes = asStringArray(entity.attributes['supported_color_modes'], []);
  const type = colorModes[0] === LightType.Brightness ? LightType.Brightness : LightType.OnOff;
  const state = asStringEnum(entity.state, LightState) ?? LightState.Off;
  const brightness = asNumber(entity.attributes['brightness']);
  return {
    id: entity.entity_id,
    name: entity.attributes.friendly_name ?? entity.entity_id,
    type,
    state,
    domain,
    brightness,
  };
}

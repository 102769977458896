import {MouseEvent, useCallback} from 'react';
import {styled} from 'styled-components';

import {UnthemedButton} from '@shared-web/components/core/button';
import {Custom} from '@shared-web/lib/react';

interface BrightnessControlProps {
  brightness?: number;
  onBrightnessChange?: (newBrightness: number) => Promise<void> | void;
}

const STEPS = 5;
const MAX_BRIGHTNESS = 255;

export const BrightnessControl: Custom<BrightnessControlProps, 'div'> = props => {
  const {brightness = 0, onBrightnessChange, ...rest} = props;

  const handleClick = useCallback(
    async (evt: MouseEvent<HTMLElement>) => {
      const value = parseFloat(evt.currentTarget.getAttribute('data-index') ?? '');
      if (isNaN(value)) {
        return;
      }
      const brightness = ((value + 1) * MAX_BRIGHTNESS) / STEPS;
      await Promise.resolve(onBrightnessChange?.(brightness));
    },
    [onBrightnessChange]
  );

  const closestIndex = Math.round((brightness * STEPS) / MAX_BRIGHTNESS - 1);

  return (
    <Wrapper {...rest}>
      {[...new Array(STEPS)].map((_, i) => (
        <Button
          $selected={closestIndex === i}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          data-index={i}
          onClickAsync={handleClick}
        >
          {' '}
        </Button>
      ))}
    </Wrapper>
  );
};

BrightnessControl.displayName = 'BrightnessControl';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 2px;
`;

const Button = styled(UnthemedButton)<{$selected: boolean}>`
  flex-grow: 1;
  background-color: ${p => (p.$selected ? '#ffffff22' : '#ffffff11')};
  border-radius: 4px;
  &:hover {
    background-color: #ffffff22;
  }
`;

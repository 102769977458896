import {createDataStore} from '@shared-web/lib/data_store';

import {markActivity} from '@src/lib/overlay_timer';

interface AppState {
  hidden: boolean;
}

const appStateStore = createDataStore<AppState>({hidden: false});

export const getAppStateStore = appStateStore.getData;
export const useAppStateStore = appStateStore.useData;
const updateAppStateStore = appStateStore.updateData;

export function setAppHidden(hidden: boolean): void {
  markActivity();
  updateAppStateStore(current => ({...current, hidden}));
}

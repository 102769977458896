import {useEffect} from 'react';
import {styled} from 'styled-components';
import {Route} from 'wouter';

import {Modal} from '@shared-web/components/core/modal';
import {useBooleanQueryString} from '@shared-web/lib/use_query_string';

import {HomePage} from '@src/components/home_page';
import {Overlay} from '@src/components/overlay';
import {startOverlayTimer} from '@src/lib/overlay_timer';
import {useAppStateStore} from '@src/stores/app_state_store';

export const App: React.FC = () => {
  const [sleep] = useBooleanQueryString('sleep', true);
  useEffect(() => startOverlayTimer(), []);

  const {hidden} = useAppStateStore();
  if (sleep && hidden) {
    return <Overlay />;
  }

  return (
    <Route path="/" nest>
      <>
        <Wrapper>
          <Route path="/" component={HomePage} />
        </Wrapper>
        <Modal />
      </>
    </Route>
  );
};
App.displayName = 'App';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: #ccc;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

import {getConnection} from '@src/lib/connection';
import {markActivity} from '@src/lib/overlay_timer';
import {Light, LightState} from '@src/lib/parse_light';

let commandId = Math.round(Math.random() * 1000);

export async function toggleLight(light: Light): Promise<void> {
  await setLightState(light, light.state === LightState.Off ? LightState.On : LightState.Off);
}

export async function setLightState(light: Light, state: LightState): Promise<void> {
  markActivity();
  const connection = await getConnection();
  commandId++;
  const service = state === LightState.On ? 'turn_on' : 'turn_off';
  await connection.sendMessagePromise({
    type: 'call_service',
    id: commandId,
    // service: state === LightState.On ? 'turn_on' : 'turn_off',
    service,
    service_data: {entity_id: light.id},
    domain: light.domain,
  });
}

export async function setLightBrightness(light: Light, brightness: number): Promise<void> {
  markActivity();
  const connection = await getConnection();
  commandId++;
  await connection.sendMessagePromise({
    type: 'call_service',
    id: commandId,
    service: 'turn_on',
    service_data: {entity_id: light.id, brightness},
    domain: light.domain,
  });
}
